import { Trainer } from "./trainer"

export const Snowboard = ({ trainers }) => {
  return (
    <div className='row'>
      {trainers?.map(el => (
        <Trainer trainer={el} key={el.trainer_ID} type={'Сноуборд'} />
      ))}
    </div>
  )
}
