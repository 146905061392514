import { useState, useEffect, useRef } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Image } from "./components/image";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { cancelOrder, cancelOrderForm, getLesson, getProfileInfo, getTrainersList } from "./api/api";
import { SportsTypes } from "./components/sportsTypes";
import Cookie from "js-cookie";
import { Modal } from "./components/modal";
import { Oval } from "react-loader-spinner";
import { PhoneInput } from "./components/phoneInput";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [activeModal, setActiveModal] = useState(false)
  const [activeRefundModal, setActiveRefundModal] = useState(false)
  const [refundMessage, setRefundMessage] = useState('')
  const [refundLesson, setRefundLesson] = useState({})
  const [loading, setLoading] = useState(false)
  const [fileNames, setFileNames] = useState('Выберите файл')
  const [files, setFiles] = useState([])
  // const [data, setData] = useState({});

  const cookie = Cookie.get('token')

  const params = new URLSearchParams(window.location.search);

  const currentDate = new Date().toISOString().split('T')[0].split('-').reverse().join('.')
  const fetchInfo = async () => {
    const response = await getProfileInfo()
    localStorage.setItem('clientInfo', JSON.stringify(response.clientInfo))

  }

  const fetchLesson = async (clientId, lessonId) => {
    const response = await getLesson(clientId, lessonId)
    console.log(response.currentOrder)
    if (response.currentOrder.canceled) {
      setActiveRefundModal(true)
    } else {
      setRefundLesson(response)
      setActiveModal(true)
    }
  }

  const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const sendRefund = async () => {
    setLoading(!loading)
    const data = await cancelOrder(refundLesson?.currentOrder?.client_ID, refundLesson?.currentOrder?.doc_ID)
    if (data) {
      setActiveModal(false)
      setRefundMessage(data.errorMessage)
      setActiveRefundModal(true)
    }
  }

  const sendRefundForm = async (e) => {
    e.preventDefault()
    setLoading(!loading)
    console.log(files)
    const formRefund = Object.fromEntries(new FormData(e.target))
    const documents = []
    for (let i = 0; i < files.length; i++) {
      const obj = {}
      const base64 = await convertToBase64(files[i])
      obj['file_extension'] = files[i].name.split('.')[1]
      obj['file_name'] = files[i].name.split('.')[0]
      obj['file'] = base64.split(',')[1]
      documents.push(obj)
    }
    console.log(documents)
    if (documents.length !== 0) {
      const data = await cancelOrderForm({
        documents: documents,
        glkLessonItem: refundLesson?.currentOrder,
        formRefund: formRefund
      })
      if (data) {
        if (data.success) {
          setRefundMessage(data.message)
        } else {
          setRefundMessage(data.errorMessage)
        }
        setActiveModal(false)
        setActiveRefundModal(true)

      }
    }
  }

  useEffect(() => {
    if (cookie) {
      fetchInfo()
    } else {
      localStorage.removeItem('clientInfo')
    }

    if (params.get('clientId') && params.get('lessonId')) {
      fetchLesson(params.get('clientId'), params.get('lessonId'))
    }
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      <Modal active={activeRefundModal} setActive={setActiveRefundModal}>
        <h2 style={{ textAlign: 'center', fontSize: '18px' }}>{refundMessage ? refundMessage : 'Возврат оформлен'}</h2>
      </Modal>
      <Modal active={activeModal} setActive={setActiveModal}>
        {
          refundLesson.success ?
            (!loading ?
              <>
                <h2 style={{ textAlign: 'center', fontSize: '18px' }}>Оформить возврат</h2>
                <form onSubmit={currentDate == refundLesson?.currentOrder?.dateBeg?.split(' ')[0] ? sendRefundForm : sendRefund}>
                  <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                    <div style={{ border: '2px solid #fc9a40', borderRadius: '20px', padding: '20px', textAlign: 'left' }}>
                      <span style={{ fontWeight: '500', color: 'black', fontSize: '16px' }}>
                        Вид занятия: {refundLesson?.currentOrder?.exerciseSort} на {refundLesson?.currentOrder?.kindOfSport?.toLowerCase()}<br />
                        Дата: {refundLesson?.currentOrder?.dateBeg?.split(' ')[0]} c {refundLesson?.currentOrder?.dateBeg?.split(' ')[1]} по {refundLesson?.currentOrder?.dateEnd?.split(' ')[1]}<br />
                        Инструктор: {refundLesson?.currentOrder?.trainer_Name}<br />
                        Итого: {refundLesson?.currentOrder?.cost} р.
                      </span>
                      {
                        currentDate == refundLesson?.currentOrder?.dateBeg?.split(' ')[0] && (
                          <div style={{ marginTop: '20px', display: 'flex', gap: '10px', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '500', color: 'black', fontSize: '16px' }}>
                              Внимание, дата возврата равна дате вашего занятия.<br /> Для оформления возврата вам нужно заполнить форму.
                            </span>
                            <input type='text' placeholder="Фамилия*" name="surname" required />
                            <input type='text' placeholder="Имя*" name="name" required />
                            <input type='text' placeholder="Отчество" name="secondName" />
                            <input type='email' placeholder="Email*" name="email" required />
                            <PhoneInput placeholder="Телефон*" />
                            <input type='' placeholder="Причина возврата*" name="comment" required />
                            <span style={{ fontWeight: '500', color: 'black', fontSize: '16px' }}>
                              Приложите подтверждающие документы<br />
                              (справка, больничный лист, паспорт 1 страница):
                            </span>
                            <label className="input-file">
                              <input type="file" required multiple onChange={(e) => {
                                let files = e.target.files;
                                let fileNames = Array.from(files).map(file => file.name);
                                setFiles(e.target.files)
                                setFileNames(fileNames.join(',\n'));
                              }} />
                              <span style={{ whiteSpace: 'pre-wrap' }}>{fileNames}</span>
                            </label>
                          </div>
                        )
                      }
                    </div>
                    <div style={{ textAlign: 'left' }}>
                      * - сумма будет возвращена на карту, с которой была произведена оплата записи к инструктору в установленные банком сроки (до 45 дней)
                    </div>
                    <div style={{ display: 'flex', gap: '15px' }}>
                      <button type="button" style={{ backgroundColor: 'white', border: '1px solid #fc9a40', color: 'black' }} onClick={() => {
                        setActiveModal(!activeModal)
                      }}>
                        Отмена
                      </button>
                      <button type="submit">Оформить<br /> возврат</button>
                    </div>
                  </div>
                </form>
              </>
              :
              <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'gap': '15px', 'width': '100%', 'flexWrap': 'wrap' }}>
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#ff7f00"
                  secondaryColor='#ff7f00'
                  ariaLabel="loading"
                />
              </div>
            )
            :
            <h2 style={{ textAlign: 'center', fontSize: '18px' }}>{refundLesson?.errorMessage}</h2>
        }
      </Modal>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <SportsTypes />
      <Features data={landingPageData.Features} />
      <Testimonials data={landingPageData.Testimonials} />
      <Gallery data={landingPageData.Gallery} />
      <Image data={landingPageData.Image} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
